
.sub-head{
    position: relative;
}
/* sub-visual */
.sub-visual {
    position:relative;
    height: 180px;
}
.sub-visual__tit {
    font-size: 3rem;
    font-weight:700;
    color:#fff;
}
.sub-visual__bg {
    position: absolute;
    top:0;
    left: 50%;
    z-index: 0;
    -webkit-transform: translate(-50%,0); -ms-transform: translate(-50%,0); transform: translate(-50%,0);
    width: 100vw;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.sub-visual__inner{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}
.sub-visual__inner .container{
    height: 100%;
}
.sub-visual__tit-wrap{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .sub-visual {
        height: 260px;
    }
    .sub-visual__tit {
        font-size: 4rem;
    }
    .sub-visual--sub-tab{
        padding-bottom: 48px;
    }
}
@media (min-width: 1024px) {
    .sub-visual {
        height: 380px;
    }
    .sub-visual__tit {
        font-size: 4.8rem;
    }
    .sub-visual--sub-tab{
        padding-bottom: 60px;
    }
}
@media (min-width: 1200px) {
}

/* sub-tab-wrap */
.sub-head__bottom{
    width: 100%;
    position: relative;
    background-color: #6d6e6d;
}
.sub-tab-wrap{
    width: 100%;
}
.sub-head__bottom .container{
    max-width: var(--container);
    padding: 0;
}
@media (min-width:768px){
    .sub-head__bottom{
        background-color: rgba(0, 0, 0, 0.5);
    }
}
@media (min-width:1024px){
}

