
.image-full-card{
    position: relative;
    width: 100%;
}
.image-full-card::before{
    content: "";
    display: block;
    position: absolute;
    width: 100vw;
    height: 100%;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    background-color: #f5f5f5;
}
.image-full-card__image{
    position: relative;
    bottom: -40px;
    width: 60%;
    margin-left: auto;
}
.image-full-card__inner{
    position: relative;
    z-index: 2;
}

@media (min-width:576px){
}
@media (min-width:768px){
    .image-full-card__image{
        position: absolute;
        bottom: 0;
        right: 0;
        margin-left: 0;
        width: 50%;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
    .image-full-card__image{
        width: 60%;
    }
}

.process-icon-wrap{
    width: 100%;
    max-width: 140px;
}
.process-icon{
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
    border-radius: 50%;
    background-color: var(--v-primary-base);
}
.process-icon__inner{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .process-icon-wrap{
        min-width: 160px;
    }
}
@media (min-width:1200px){
    .process-group{
        position: relative;
    }
    .process-group::before{
        content: "";
        display: block;
        position: absolute;
        width: calc(100% + 24px);
        height: 24px;
        left: 50%;
        top: calc(50% - 49px);
        transform: translate(-50%, -50%);
        background-image: url(/images/sub/support/commodity/process-arrow.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: calc(100% - 24px) auto;

    }
}

